

































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {toInstanceForce} from '~/utils/Serializer'
import {Comment} from '~/components/community/community-models'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import CloseUpImage from '~/components/closeup-image.vue'

@Component({
    components: {
        CloseUpImage
    },
    metaInfo() {
        return {
            title: 'Community Comments'
        }
    }
})
export default class Comments extends mixins(QueryParserMixin, PaginationMixin) {
    comments: Comment[] = []

    step: number = 20

    // data query
    async toPage(pageNumber: number) {
        this.currentPage = pageNumber
        this.updateComments()
    }

    queryDef = [
        {localVar: 'currentPage', queryStr: 'page', type: QueryType.Number},
    ]

    created() {
        this.parseQuery()
        this.updateComments()
    }

    updateComments() {
        const param: { [key: string]: string } = {
            member_id: this.$route.params['member_id'],
            skip: (this.currentPage - 1) * this.step + '',
            limit: this.step + '',
            blocked: 'both',
            count: 'true'
        }
        createRequest('/community-v2/comments', 'get', param).send()
            .then((res) => {
                this.totalCount = res.data['count']
                this.comments = []
                res.data.comments.forEach((comment: { [key: string]: any }) => {
                    this.comments.push(toInstanceForce(new Comment(), comment))
                })
            })
    }

    // mounted() {}

    findComment(postId: string, commentId: string) {
        return this.comments.find((comment: Comment) => {
            return (comment.comment_id == commentId && comment.post_id == postId)
        })
    }

    // blocking
    blockComment(postId: string, commentId: string) {
        createRequest(`/community-v2/comment/${postId}/${commentId}/block`, 'patch').send()
            .then((res) => {
                const comment = this.findComment(postId, commentId)
                if (comment != undefined) {
                    comment.blocked = true
                }
            })
    }

    unblockComment(postId: string, commentId: string) {
        createRequest(`/community-v2/comment/${postId}/${commentId}/unblock`, 'patch').send()
            .then((res) => {
                const comment = this.findComment(postId, commentId)
                if (comment != undefined) {
                    comment.blocked = false
                }
            })
    }

    tableRowClassName({row}) {
        const comment: Comment = row
        if (comment.blocked) {
            return 'comment-blocked'
        }
        if (comment.deleted)
            return 'comment-deleted'
        if (comment.best_answer)
            return 'comment-best-ans'
        return ''
    }

    viewPost(postId: string) {
        this.$router.push('/post/' + postId)
    }
}
