var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comments",attrs:{"align":"center"}},[_c('div',{staticClass:"container"},[(_vm.totalCount / _vm.step > 1)?_c('el-pagination',{attrs:{"total":_vm.totalCount,"page-size":_vm.step,"current-page":_vm.currentPage,"layout":"prev, pager, next"},on:{"current-change":_vm.toPage}}):_vm._e(),_c('el-table',{staticClass:"mb-3",attrs:{"data":_vm.comments,"size":"small","row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"label":"Post","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":("/post/" + (row.post_id))}},[_vm._v(" "+_vm._s(row.post_id)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Content","prop":"content","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(row.content)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Vote","prop":"vote_count","align":"center"}}),_c('el-table-column',{attrs:{"label":"Img","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.image),function(image){return _c('close-up-image',{key:image.url,staticClass:"comment-img",attrs:{"src":image.url}})})}}])}),_c('el-table-column',{attrs:{"label":"Time","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(new Date(row.created).toLocaleString())+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Block","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.blocked)?_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.blockComment(row.post_id, row.comment_id)}}},[_vm._v("Block ")]):_c('el-button',{attrs:{"type":"success","size":"mini"},on:{"click":function($event){return _vm.unblockComment(row.post_id, row.comment_id)}}},[_vm._v("Unblock ")])]}}])})],1),(_vm.totalCount / _vm.step > 1)?_c('el-pagination',{attrs:{"total":_vm.totalCount,"page-size":_vm.step,"current-page":_vm.currentPage,"layout":"prev, pager, next"},on:{"current-change":_vm.toPage}}):_vm._e()],1),(_vm.comments.length === 0)?_c('div',[_vm._v(" No comment")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }